import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

import DescriptionBlock from "../../components/LayoutElements/DescriptionBlock/DescriptionBlock";
import Accordion from "../../components/LayoutElements/Accordion/Accordion";

import "./DashboardView.scss";
import firebase from "./../../Firebase";
import "firebase/firestore";

const Dashboard = () => {
  const [JobOffers, setJobOffers] = useState([]);
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    const db = firebase.firestore();
    return db.collection("job-ads").onSnapshot((snapshot) => {
      const jobs = [];
      snapshot.forEach((doc) => jobs.push({ ...doc.data(), id: doc.id }));
      setJobOffers(jobs);
      setLoaded(true);
    });
  }, []);

  return (
    <div className="dashboard">
      <div className="content">
        <div>
          <DescriptionBlock
            isLandingBlock={false}
            title="Ads Page"
            center={true}
            header="Propeller Dashboard"
            description={
              isLoaded ? (
                "Total ads: " + JobOffers.length
              ) : (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              )
            }
          />
        </div>
        <div>
          {JobOffers.length >= 1 ? (
            <div className="highlighted">
              <div className="container">
                <div className="offset-lg-1 col-lg-10">
                  <div className="me-auto">
                    <Link to={"/addposition"}>
                      <button className="btn btn-primary -position -round -outlined -blackborder">
                        Add a position
                      </button>
                    </Link>
                  </div>
                  <div className="accordion ">
                    {isLoaded
                      ? JobOffers.map((offer, i) => {
                          return (
                            <Accordion
                              key={i}
                              title={offer.title}
                              titleOne={offer.titleOne}
                              titleTwo={offer.titleTwo}
                              titleThree={offer.titleThree}
                              jobDescription={offer.jobDescription}
                              whatWeOffer={offer.whatWeOffer}
                              contact={offer.contact}
                              requirements={offer.requirements}
                              titleFour={offer.titleFour}
                              bonusSkills={offer.bonusSkills}
                              active={offer.active ? "Active" : "Not active"}
                              activeStatus={offer.active}
                            />
                          );
                        })
                      : ""}
                  </div>
                </div>
              </div>
            </div>
          ) : isLoaded ? (
            <div className="ml-5">
              <Link to={"/addposition"}>
                <button className="btn btn-primary -position -round -outlined -blackborder">
                  Add a position
                </button>
              </Link>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
