import { useHistory, useParams } from "react-router";
import Spinner from "react-bootstrap/Spinner";
import firebase from "./../../Firebase";
import "firebase/firestore";
import DescriptionBlock from "../LayoutElements/DescriptionBlock/DescriptionBlock";
import { useEffect, useState } from "react";

const EditPosition = () => {
  const { jobid } = useParams();
  const history = useHistory();
  const [data, setData] = useState([]);
  const [title, setTitle] = useState(data.title);
  const [isLoaded, setLoaded] = useState(false);
  const [requirement, setRequirement] = useState("");
  const [bonusSkills, setBonusSkills] = useState([data.bonusSkills]);
  const [bonusSkill, setBonusSkill] = useState("");
  const [isDeleted, setDeleted] = useState(false);
  const [requirements, setRequirements] = useState([data.requirements]);
  const [jobDescription, setjobDescription] = useState(data.jobDescription);
  const [whatWeOffer, setwhatWeOffer] = useState(data.whatWeOffer);
  // const editTitle = isLoaded ? title : "Loading";

  const onUpdate = () => {
    const db = firebase.firestore();
    db.collection("job-ads")
      .doc(data.title.replace("/", ""))
      .set({ ...data, jobDescription, requirements, bonusSkills, whatWeOffer });
  };
  const addRequestHandler = (e) => {
    e.preventDefault();
    const reqs = [];
    reqs.push(...requirements, requirement);
    setRequirements(reqs);
    setRequirement("");
  };

  const bonusRequirementHandler = (e) => {
    e.preventDefault();
    const bonus = [];
    bonus.push(...bonusSkills, bonusSkill);
    setBonusSkills(bonus);
    setBonusSkill("");
  };

  useEffect(() => {
    const db = firebase.firestore();
    var jobRef = db.collection("job-ads").doc(jobid);

    jobRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          setData(doc.data());
          setLoaded(true);
          setRequirements(doc.data().requirements);
          setBonusSkills(doc.data().bonusSkills);
          setTitle(doc.data().title);
          setjobDescription(doc.data().jobDescription);
          setwhatWeOffer(doc.data().whatWeOffer);
        } else {
          console.log("Document doesn't exist");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="addposition">
      <div className="container mt-5 mb-5">
        <div className="card">
          <div className="card-body mt-5">
            <DescriptionBlock
              title={
                isLoaded ? (
                  title
                ) : (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                )
              }
              header={"Propeller Dashboard"}
              description={"Edit position "}
              center={true}
            />

            {isLoaded ? (
              <div className="form-group px-3">
                <form className="position-form">
                  <label>Job description:</label>

                  <textarea
                    value={jobDescription}
                    type="text"
                    className="form-control"
                    id="job-description"
                    rows={6}
                    maxLength="300"
                    onChange={(e) => {
                      setjobDescription(e.target.value);
                    }}
                  />
                  <br />

                  <label>Requirements:</label>

                  <input
                    className="form-control"
                    value={requirement}
                    id="requirements"
                    onChange={(e) => setRequirement(e.target.value)}
                  />
                  <div className="text-center mt-3">
                    <button
                      className="btn btn-primary -round -blackborder  -outlined"
                      onClick={(e) => addRequestHandler(e)}
                    >
                      <strong>Add requirement</strong>
                    </button>
                  </div>
                  <br />
                  <span>
                    <b>Current:</b>
                  </span>
                  <br />
                  <br />
                  {requirements.map((req, i) => {
                    return (
                      <div className="row">
                        <div className="col-sm-8">
                          <ul className="requirement-list -decoration">
                            <li key={i}>{req} </li>
                          </ul>
                        </div>
                        <div className="col-sm-4">
                          <button
                            className="btn btn-danger btn-sm"
                            onClick={(e) => {
                              e.preventDefault();
                              requirements.splice(i, 1);
                              setDeleted(!isDeleted);
                            }}
                          >
                            X
                          </button>
                        </div>
                        <hr />
                      </div>
                    );
                  })}
                  <br />

                  <label htmlFor="bonusSkills">Bonus skills: </label>
                  <br />
                  <input
                    value={bonusSkill}
                    type="text"
                    className="form-control"
                    id="bonusSkills"
                    onChange={(e) => setBonusSkill(e.target.value)}
                  />
                  <div className="text-center mt-3">
                    <button
                      className="btn btn-primary -round -blackborder  -outlined"
                      onClick={(e) => bonusRequirementHandler(e)}
                    >
                      <strong>Add skill</strong>
                    </button>
                  </div>
                  <span>
                    <b>Current:</b>
                  </span>
                  <br />
                  <br />
                  {bonusSkills.length > 0
                    ? bonusSkills.map((skill, i) => {
                        return (
                          <div className="row">
                            <div className="col-sm-8">
                              <ul className="requirement-list -decoration">
                                <li key={i}>{skill} </li>
                              </ul>
                            </div>

                            <div className="col-sm-4">
                              <button
                                className="btn btn-danger btn-sm"
                                onClick={(e) => {
                                  e.preventDefault();
                                  bonusSkills.splice(i, 1);
                                  setDeleted(!isDeleted);
                                }}
                              >
                                X
                              </button>
                            </div>
                            <hr />
                          </div>
                        );
                      })
                    : ""}
                  <br />
                  <label>What we offer:</label>
                  <textarea
                    value={whatWeOffer}
                    type="text"
                    className="form-control"
                    id="whatWeOffer"
                    rows={6}
                    onChange={(e) => setwhatWeOffer(e.target.value)}
                  />
                  <div className="text-center mt-3 mb-4">
                    <button
                      className="btn btn-primary -position -outlined -round -blackborder"
                      onClick={() => {
                        onUpdate();
                        history.push("/dashboard");
                      }}
                    >
                      Update
                    </button>
                  </div>
                </form>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPosition;
