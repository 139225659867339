import React, { useEffect, useContext, useState } from "react";
import { auth } from "../Firebase";
import firebase from "firebase/app";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  const signUp = (email, password) => {
    return auth.createUserWithEmailAndPassword(email, password);
  };

  const logIn = (email, password) => {
    return auth.signInWithEmailAndPassword(email, password);
  };

  const logOut = () => {
    return auth.signOut();
  };

  //Depricated on 12/08/2022.
  //Company logins to be switched to google. 
  //Code commented out by Haris Muslić on 12/08/2022.

  //damir@propeller.ba
  const signInWithMicrosoft = () => {
    const microsoftProvider = new firebase.auth.OAuthProvider("microsoft.com");
    microsoftProvider.setCustomParameters({
      prompt: "consent",
      login_hint: "employee@propeller.ba",
      tenant: "a90192a5-c003-40c6-9923-26b62cbec8fc",
    });

    auth.signInWithPopup(microsoftProvider).then((data) => {
      console.log(data);
    });

    // auth.signInWithRedirect(microsoftProvider);
  };

  //New Login code for singung up with google
  //Code Written by Haris Muslić on 12/08/2022.
  const signInWithGoogle = () => {
    const googleProvider = new firebase.auth.OAuthProvider("google.com");

    googleProvider.addScope("email");
    googleProvider.addScope("profile");

    auth.signInWithPopup(googleProvider).then((data) => {
      console.log(data);

      //Getting user email.
      const email = data.additionalUserInfo.profile.email;

      //Getting user email domain.
      const emailDomain = email.split("@")[1];

      //Checking if user email domain is propeller.ba.
      if(emailDomain !== 'propeller.ba'){
        //Log out user if not
        logOut();

      }


    });
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    signUp,
    logIn,
    logOut,
    signInWithMicrosoft,
    signInWithGoogle
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
