import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import DescriptionBlock from "../../components/LayoutElements/DescriptionBlock/DescriptionBlock";
import TestimonialForm from "../../components/TestimonialComponents/TestimonialForm";
import TestimonialItem from "../../components/TestimonialComponents/TestimonialItem";
import firebase from "../../Firebase";
import "./Testimonial.scss";

const Testimonial = () => {
  const [show, setShow] = useState(false);
  const [company, setCompany] = useState("");
  const [testimonial, setTestimonial] = useState("");
  const [position, setPosition] = useState("");
  const [author, setAuthor] = useState("");
  const [testimonials, setTestimonials] = useState([]);
  const db = firebase.firestore();

  useEffect(() => {
    db.collection("testimonials").onSnapshot((snapshot) => {
      let temp = [];
      snapshot.forEach((doc) => {
        temp.push({ ...doc.data(), id: doc.id });
      });
      setTestimonials(temp);
    });
  }, []);

  const createTestimonial = async () => {
    await db.collection("testimonials").add({
      author,
      company,
      testimonial,
      position,
    });
    setShow(false);
    setCompany("");
    setAuthor("");
    setTestimonial("");
    setPosition("");
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="testimonial-screen">
      <div className="container mb-5">
        <div className="mt-5">
          <DescriptionBlock
            isLandingBlock={false}
            title={"Testimonials"}
            center={true}
            header={"Propeller Dashboard"}
            description={"Propeller Testimonials"}
          />
        </div>
        <div className="mt-5">
          <div className="me-auto">
            <button
              onClick={handleShow}
              className="btn btn-primary -position -round -outlined -blackborder"
            >
              Add a testimonial
            </button>
          </div>
        </div>
        <div className="mt-5 px-3">
          {testimonials.map((test) => {
            return (
              <TestimonialItem
                author={test.author}
                testimonial={test.testimonial}
                company={test.company}
                position={test.position}
                id={test.id}
                key={test.id}
              />
            );
          })}
        </div>
      </div>

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add a testimonial</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TestimonialForm
            author={author}
            setAuthor={setAuthor}
            company={company}
            testimonial={testimonial}
            setCompany={setCompany}
            setTestimonial={setTestimonial}
            position={position}
            setPosition={setPosition}
          />
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleClose}>
            Close
          </button>
          <button className="btn btn-primary" onClick={createTestimonial}>
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Testimonial;
