import firebase from "firebase/app";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCPDCYbGt9hKaFHB8DZyI93MWBG0gPk0nE",
  authDomain: "propeller-web-stage.firebaseapp.com",
  projectId: "propeller-web-stage",
  storageBucket: "propeller-web-stage.appspot.com",
  messagingSenderId: "900067166430",
  appId: "1:900067166430:web:bf456463ff657d522a1a4f",
  measurementId: "G-H020XB4861",
};

firebase.initializeApp(firebaseConfig);

export default firebase;
export const auth = firebase.auth();
