import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import logo from "../../../assets/images/propeller-logo.svg";
import "firebase/firestore";
import { Navbar, Nav, Container } from "react-bootstrap";
import "./MainNavbar.scss";

function MainNavbar() {
  const { logOut, currentUser } = useAuth();
  const history = useHistory();
  const [expanded, setExpanded] = useState(false);

  const handleLogout = async () => {
    try {
      await logOut();
      history.push("/");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Navbar
      className={currentUser ? "" : "d-none"}
      sticky="top"
      expand="md"
      variant="light"
      bg="light"
      collapseOnSelect
      expanded={expanded}
    >
      <Container>
        <Navbar.Brand href="#home">
          <img src={logo} alt={"logo"} />
        </Navbar.Brand>
        <Navbar.Toggle
          onClick={() => setExpanded(!expanded)}
          aria-controls="basic-navbar-nav"
        />
        <Navbar.Collapse className="justify-content-center text-center justify-content-md-end ">
          <Nav className="">
            <Nav.Link
              bsPrefix="navbar-link "
              onClick={() => {
                history.push("/");
                setExpanded(false);
              }}
            >
              <div>
                <span>Home</span>
              </div>
            </Nav.Link>
            <Nav.Link
              bsPrefix="navbar-link"
              onClick={() => {
                history.push("/ads");
                setExpanded(false);
              }}
            >
              <div>
                <span>Ads</span>
              </div>
            </Nav.Link>
            <Nav.Link
              bsPrefix="navbar-link"
              onClick={() => {
                history.push("/vacation");
                setExpanded(false);
              }}
            >
              <div>
                <span>Vacation</span>
              </div>
            </Nav.Link>
            <Nav.Link
              bsPrefix="navbar-link"
              onClick={() => {
                history.push("/testimonials");
                setExpanded(false);
              }}
            >
              <div>
                <span>Testimonials</span>
              </div>
            </Nav.Link>
            <Nav.Link
              bsPrefix="navbar-link"
              onClick={() => {
                setExpanded(false);
                handleLogout();
              }}
            >
              <div>
                <span>Log Out</span>
              </div>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MainNavbar;
