import React, { useEffect, useState } from "react";
import DescriptionBlock from "../LayoutElements/DescriptionBlock/DescriptionBlock";
import firebase from "../../Firebase";
import { useAuth } from "../../contexts/AuthContext";
import { Spinner } from "react-bootstrap";
import "./VacationEmployee.scss";
import VacationRequest from "./VacationRequest";

const VacationEmployee = () => {
  const db = firebase.firestore();
  const [vacationStartDate, setVacationStartDate] = useState("");
  const [vacationEndDate, setVacationEndDate] = useState("");
  const [myVacationRequests, setMyVacationRequests] = useState([]);
  const [additionalNote, setAdditionalNote] = useState("");
  const [isUpdating, setUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  const { currentUser } = useAuth();
  const vacationRequest = {
    userEmail: currentUser.email,
    vacationStartDate,
    vacationEndDate,
    createdAt: Date.now(),
    status: "PENDING",
    additionalNote,
  };

  useEffect(() => {
    let today = new Date();
    let day = today.getDate();
    let month = today.getMonth() + 1;
    let year = today.getFullYear();

    if (day < 10) {
      day = "0" + day;
    }

    if (month < 10) {
      month = "0" + month;
    }

    setCurrentDate(year + "-" + month + "-" + day);
  }, []);

  //IF WE ADD VACATION DAYS COUNTER
  // useEffect(() => {
  //     const dayDifference = new Date(vacationEndDate).getTime() - new Date(vacationStartDate).getTime()
  //     console.log(Math.round(dayDifference)/(1000*60*60*24))
  // }, [vacationEndDate])

  const deleteVacationRequest = (id) => {
    return db.collection("vacation-requests").doc(id).delete();
  };

  const setUpdateMode = (startDate, endDate) => {
    setUpdate(true);
    setVacationStartDate(startDate);
    setVacationEndDate(endDate);
  };

  const updateVacationRequest = async (e, id) => {
    e.preventDefault();

    if (vacationEndDate > vacationStartDate) {
      try {
        setError("");
        await db.collection("vacation-requests").doc(id).update({
          vacationStartDate,
          vacationEndDate,
          updatedAt: Date.now(),
        });
        setUpdate(false);
        setVacationStartDate("");
        setVacationEndDate("");
        console.log("Updated successfully");
      } catch (error) {
        console.log(error);
      }
    } else {
      return setError("Vacation end date must be after vacation start date!");
    }
  };

  useEffect(() => {
    setIsLoading(true);
    return db
      .collection("vacation-requests")
      .where("userEmail", "==", currentUser.email)
      .onSnapshot((snapshot) => {
        let requests = [];
        snapshot.forEach((doc) => {
          requests.push({ ...doc.data(), id: doc.id });
        });
        setMyVacationRequests(requests);
        setIsLoading(false);
      });
  }, []);

  const sendVacationRequest = async (e) => {
    e.preventDefault();

    if (vacationEndDate > vacationStartDate) {
      try {
        setError("");
        await db.collection("vacation-requests").add(vacationRequest);
        setVacationStartDate("");
        setVacationEndDate("");
        setAdditionalNote("");
        console.log("Success");
      } catch (error) {
        console.log(error);
      }
    } else {
      return setError("Vacation end date must be after vacation start date!");
    }
  };
  return (
    <div className="container">
      <div className="mt-5">
        <DescriptionBlock
          isLandingBlock={false}
          title="Vacation Request"
          center={true}
          header="Propeller Dashboard"
          description="Send a request for vacation"
        />
      </div>
      <div className="vacation-form text-center row">
        <span className="text-center mb-3">{error}</span>
        <form onSubmit={sendVacationRequest}>
          <div className="col mb-3">
            <label>From: </label>
            <br />

            <input
              min={currentDate}
              className="w-50"
              value={vacationStartDate}
              onChange={(e) => setVacationStartDate(e.target.value)}
              type="date"
            />
          </div>
          <div className="col mb-3">
            <label>To: </label>
            <br />{" "}
            <input
              min={vacationStartDate}
              className="w-50"
              value={vacationEndDate}
              onChange={(e) => setVacationEndDate(e.target.value)}
              type="date"
            />
          </div>
          <div className="col">
            <label>Additional note:</label>
            <br />
            <textarea
              className="w-50"
              value={additionalNote}
              onChange={(e) => setAdditionalNote(e.target.value)}
            />
          </div>
          <div className="col">
            <input type="submit" />
          </div>
        </form>
      </div>
      <div className="mt-5 mb-5 ">
        <DescriptionBlock
          center={true}
          title="My Vacation Requests"
          description={
            isLoading ? (
              <Spinner animation="border" role="status" />
            ) : (
              "Sent requests"
            )
          }
        />
        {myVacationRequests?.map((req, i) => {
          return (
            <VacationRequest
              key={i}
              req={req}
              isUpdating={isUpdating}
              currentDate={currentDate}
              vacationStartDate={vacationStartDate}
              vacationEndDate={vacationEndDate}
              updateVacationRequest={updateVacationRequest}
              setUpdateMode={setUpdateMode}
              deleteVacationRequest={deleteVacationRequest}
              setVacationStartDate={setVacationStartDate}
              setVacationEndDate={setVacationEndDate}
            />
          );
        })}
      </div>
    </div>
  );
};

export default VacationEmployee;
