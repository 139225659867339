import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useEffect, useState } from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "./assets/sass/app.scss";

import MainNavbar from "./components/LayoutElements/MainNavbar/MainNavbar";
import Home from "./views/Home";
import Dashboard from "./views/DashboardView/Dashboard";
import AddPosition from "./components/Positions/AddPosition";
// import LoginPage from "./components/HomeComponents/LoginSection/LoginPage";
// import Signup from "./components/HomeComponents/SignupSection/Signup";
import EditPosition from "./components/Positions/EditPosition";
import { AuthProvider } from "./contexts/AuthContext";
import PrivateRoute from "./components/PrivateRoute";
import Vacation from "./views/VacationView/Vacation";
import Testimonial from "./views/TestimonialView/Testimonial";

function App() {
  useEffect(() => {});
  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <MainNavbar />
          <Switch>
            <Route exact path="/" component={Home} />
            <PrivateRoute exact path="/ads" component={Dashboard} />
            <PrivateRoute exact path="/addposition" component={AddPosition} />
            <PrivateRoute exact path="/vacation" component={Vacation} />
            <PrivateRoute exact path="/testimonials" component={Testimonial} />
            {/* <Route exact path="/login" component={LoginPage} /> */}
            {/* <Route exact path="/register" component={Signup} /> */}
            <PrivateRoute
              exact
              path="/position/:jobid"
              component={EditPosition}
            />
          </Switch>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
